/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import ReactDOMServer from 'react-dom/server';
import moment from 'moment';

import { defineMessages, useIntl } from 'react-intl';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { Container, Grid, Checkbox, Dimmer, Loader } from 'semantic-ui-react';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UniversalLink } from '@plone/volto/components';
import { ReadMore } from '@package/components';
import { getYCApi } from '@package/actions';
import {
  getReactSelectAriaLiveMessages,
  getReactSelectScreenReaderStatus,
} from '@package/helpers';
import YCMap from './YCMap';
import { getQueryStringResults } from '@plone/volto/actions';

import {
  MAP_TYPE_YC,
  MAP_TYPE_SPAZI_E_SERVIZI,
  MAP_TYPE_CREATIVITA,
  MAP_TYPE_WEB_RADIO,
  MAP_TYPE_STREETART_SPAZI_CREATIVI,
  MAP_TYPE_TAPPE_YOUZ,
} from './Sidebar';

import config from '@plone/volto/registry';

const messages = defineMessages({
  no_results: {
    id: 'no_results',
    defaultMessage: 'Nessun risultato trovato',
  },
  results_found: {
    id: 'results_found',
    defaultMessage: 'Trovati {number} risultati',
  },
  convenzioni: {
    id: 'YCMap_convenzioni',
    defaultMessage: 'Convenzioni',
  },
  progetti: {
    id: 'YCMap_progetti',
    defaultMessage: 'Progetti',
  },
  punti_distribuzione: {
    id: 'YCMap_punti_distribuzione',
    defaultMessage: 'Punti di distribuzione',
  },
  categoria: {
    id: 'YCMap_categoria',
    defaultMessage: 'Categoria',
  },
  provincia: {
    id: 'YCMap_provincia',
    defaultMessage: 'Provincia',
  },
  comune: {
    id: 'YCMap_comune',
    defaultMessage: 'Comune',
  },
  phone: {
    id: 'phone',
    defaultMessage: 'Tel.',
  },
  mail: {
    id: 'mail',
    defaultMessage: 'E-mail',
  },
  scheda: {
    id: 'Go to detail',
    defaultMessage: 'Vai alla scheda',
  },
  website: {
    id: 'Go to website',
    defaultMessage: 'Vai al sito web',
  },
  tappa: {
    id: 'Go to stage',
    defaultMessage: 'Vai alla tappa',
  },
  tipologia_spazio_servizio: {
    id: 'Tipologia di spazio o servizio',
    defaultMessage: 'Tipologia',
  },
  status_tappe_youz: {
    id: 'Status tappe Youz',
    defaultMessage: 'Status',
  },
  street_art: {
    id: 'Street Art',
    defaultMessage: 'Street Art',
  },
  spazi_creativi: {
    id: 'Spazi creativi',
    defaultMessage: 'Spazi creativi',
  },
  convenzione_categorie: {
    id: 'Categoria convenzioni',
    defaultMessage: 'Categoria convenzioni',
  },
  arrow_close: {
    id: 'Chiudi le opzioni',
    defaultMessage: 'Chiudi le opzioni',
  },
  arrow_open: {
    id: 'Apri le opzioni',
    defaultMessage: 'Apri le opzioni',
  },
  next_stage: {
    id: 'Next stages',
    defaultMessage: 'Prossime tappe',
  },
  prev_stage: {
    id: 'Previous stages',
    defaultMessage: 'Tappe passate',
  },
  keyboardInstructions: {
    id: 'YCMap_keyboardInstructions',
    defaultMessage:
      'Usa le frecce per navigare tra i luoghi. Premi Enter per selezionare il luogo. Premi la barra spaziatrice per aprire il link del luogo.',
  },
});

const Body = (props) => {
  const intl = useIntl();
  const today = new Date();
  const { data, isEditMode } = props;
  const dispatch = useDispatch();

  const defaultFilters = {
    convenzioni: true,
    progetti: false,
    punti_distribuzione: false,
    comune: null,
    provincia: null,
    street_art: true,
    spazi_creativi: true,
    status_tappe_youz: null,
    tipologia_spazio_servizio: null,
  };

  const CAT_SPAZIO = {
    WEBRADIO: 4,
    AGGREGAZIONE_POLIVALENTE: 9,
    SALA_PROVE: 10,
    STREETART: 24,
    SPAZICREATIVI: 25,
  };

  const [filters, setFilters] = useState(defaultFilters);
  const [collapsedFilters, setCollapsedFilters] = useState(false);
  const [zoom, setZoom] = useState(null);
  const map_type = data.mapType ?? MAP_TYPE_YC;
  const [comuniYouz, setComuniYouz] = useState([]);

  const subrequestsSuffixID = props.content?.UID + '-' + props.id;

  const province = useSelector((state) => {
    return (
      state.YCApi.subrequests?.[
        subrequestsSuffixID + 'province'
      ]?.result?.province?.map((p) => ({
        value: p.provincia.id,
        label: p.provincia.descrizione,
      })) ?? []
    );
  });

  const comuni = useSelector((state) => {
    return (
      state.YCApi.subrequests?.[
        subrequestsSuffixID + 'comuni'
      ]?.result?.comuni.map((p) => {
        return {
          value: p.comune.id,
          label: p.comune.descrizione,
          provincia: p.comune.provincia,
        };
      }) ?? []
    );
  });

  //Select Status Youz
  const status_tappe_youz = [
    {
      value: 'next',
      label: intl.formatMessage(messages.next_stage),
    },
    {
      value: 'prev',
      label: intl.formatMessage(messages.prev_stage),
    },
  ];

  const tipologie_spazi_e_servizi = useSelector(
    (state) =>
      state.YCApi.subrequests?.[
        subrequestsSuffixID + 'tipiSpazi'
      ]?.result?.tipiSpazio?.map((p) => {
        return {
          value: p.tipoSpazio.id,
          label: p.tipoSpazio.descrizione,
        };
      }) ?? [],
  );

  const convenzione_categorie = useSelector(
    (state) =>
      state.YCApi.subrequests?.[
        subrequestsSuffixID + 'convenzione_categorie'
      ]?.result?.convenzione_categorie?.map((c) => {
        return {
          value: c.convenzione_categoria.id,
          label: c.convenzione_categoria.descrizione,
        };
      }) ?? [],
  );

  //  Create the Icon
  const LeafIcon = (options, p) => {
    return {
      html: ReactDOMServer.renderToString(
        p.properties?.icon ? (
          <img
            src={p.properties.icon}
            alt={p.properties.name && p.properties.name}
            width={36}
          />
        ) : options?.iconUrl ? (
          // used from youz map
          <img
            src={options.iconUrl}
            alt={p.title}
            width={options.iconSize[0]}
            height={options.iconSize[1]}
          />
        ) : (
          <FontAwesomeIcon icon={['fas', 'map-marker-alt']} />
        ),
      ),
      iconSize: [25, 41],
      iconAnchor: [12.5, 20.5],
      ...options,
    };
  };

  const getUrlScheda = (p) => {
    let s = p.properties.scheda;
    let params = {};
    let basePath = config.settings.isMultilingual ? '/' + intl.locale : '';
    s.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
      params[key] = value;
    });

    let id = params.id || p.properties.id;
    if (p.properties.tipo === 'punto di distribuzione') {
      id = p.properties.id_internal;
    }

    if (id) {
      return basePath + '/youngercard/maps/' + p.properties.tipo + '/' + id;
    }
    return null;
  };

  // Verifica che la tappa sia passata o meno
  const checkPastStages = (tappa) => {
    if (tappa?.closing_date) {
      const closingDate = new Date(tappa.closing_date);
      if (closingDate < today) {
        return 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMi40ODQiIGhlaWdodD0iNDAuMTQxIiB2aWV3Qm94PSIwIDAgMzIuNDg0IDQwLjE0MSI+CiAgPGcgaWQ9IlJhZ2dydXBwYV8xIiBkYXRhLW5hbWU9IlJhZ2dydXBwYSAxIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwIDAuMTQxKSI+CiAgICA8cGF0aCBpZD0ibWFwcGluLXBpbi1tYXAtc3ZncmVwby1jb20iIGQ9Ik0yLDE1di43MzJhMTUuNzMxLDE1LjczMSwwLDAsMCwzLjQ0Nyw5LjgyN0wxNyw0MCwyOC41NTMsMjUuNTU5QTE1LjczMSwxNS43MzEsMCwwLDAsMzIsMTUuNzMyVjE1QTE1LDE1LDAsMSwwLDIsMTVabTE1LDVhNSw1LDAsMSwwLTUtNUE1LDUsMCwwLDAsMTcsMjBaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMikiIGZpbGw9IiMwMzA3MDgiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPgogICAgPHBhdGggaWQ9IlRyYWNjaWF0b18xIiBkYXRhLW5hbWU9IlRyYWNjaWF0byAxIiBkPSJNOS4yLjU3OFE4LjY0MS0uNjU2LDguNTE2LS45NjlsLS45NTMtMi4ycS0uNDM3LTEuMDE2LS43NS0xLjY1Nkw2LjIzNC02YTEwLjk3NywxMC45NzcsMCwwLDAtMy45NTMtNC42NzJxMi42NzItMi4yNSw0Ljc2Ni0yLjI1YTIuNTc4LDIuNTc4LDAsMCwxLDIuMTg4LDEuMDcsMTAuMDU0LDEwLjA1NCwwLDAsMSwxLjM1OSwzLjcxMSw4Mi41NTEsODIuNTUxLDAsMCwxLDYuMzI4LTEwLjY0MSwxNC4xODYsMTQuMTg2LDAsMCwxLDMuNDc3LTMuNSw3LjQ2LDcuNDYsMCwwLDEsMy44MzYtLjg1OSwxMC40MDcsMTAuNDA3LDAsMCwxLDMuMjUuNTc4LDQwLjI3Miw0MC4yNzIsMCwwLDAtOC45LDguNzgxQTEwNi4wMTQsMTA2LjAxNCwwLDAsMCw5LjIuNTc4WiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNSAyMykiIGZpbGw9IiNlMTZhOGMiLz4KICA8L2c+Cjwvc3ZnPgo=';
      } else
        return 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMCIgaGVpZ2h0PSI0MCIgdmlld0JveD0iMCAwIDMwIDQwIj4KICA8cGF0aCBpZD0ibWFwcGluLXBpbi1tYXAtc3ZncmVwby1jb20iIGQ9Ik0yLDE1di43MzJhMTUuNzMxLDE1LjczMSwwLDAsMCwzLjQ0Nyw5LjgyN0wxNyw0MCwyOC41NTMsMjUuNTU5QTE1LjczMSwxNS43MzEsMCwwLDAsMzIsMTUuNzMyVjE1QTE1LDE1LDAsMSwwLDIsMTVabTE1LDVhNSw1LDAsMSwwLTUtNUE1LDUsMCwwLDAsMTcsMjBaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMikiIGZpbGw9IiMwMzA3MDgiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPgo8L3N2Zz4K';
    } else return null;
  };

  const markers = useSelector((state) => {
    if (map_type === MAP_TYPE_TAPPE_YOUZ) {
      const filterTappe =
        state.querystringsearch?.subrequests?.['tappe_mappa_youz']?.items
          .length > 0
          ? state.querystringsearch?.subrequests?.[
              'tappe_mappa_youz'
            ]?.items.filter(
              (tappa) =>
                tappa.geolocation.latitude && tappa.geolocation.longitude,
            )
          : [];

      return filterTappe.map((p) => ({
        latitude: p.geolocation.latitude,
        longitude: p.geolocation.longitude,
        title: p.title,
        id: p.UID,
        address: p.street,
        item: p,
        popupContent: (
          <div>
            <strong>{p.title}</strong>
            <div>{p.street}</div>
            <div className="popup-bottom">
              <UniversalLink item={p}>
                {intl.formatMessage(messages.tappa)}
              </UniversalLink>
            </div>
          </div>
        ),
        divIcon: LeafIcon(
          {
            iconUrl: checkPastStages(p),
            iconSize: [35, 40],
            className: '',
          },
          p,
        ),
      }));
    } else {
      return (
        state.YCApi.subrequests?.[
          subrequestsSuffixID + 'subjects'
        ]?.result?.features
          ?.sort((p1, p2) =>
            p1.properties.name
              .toLowerCase()
              .localeCompare(p2.properties.name.toLowerCase()),
          )
          .map((p) => ({
            latitude: p.geometry.coordinates[1],
            longitude: p.geometry.coordinates[0],
            title: p.properties.name,
            id: p.properties.id,
            address: p.properties.address,
            href: getUrlScheda(p),
            popupContent: (
              <div>
                <strong>{p.properties.name}</strong>
                <div>{p.properties.address}</div>
                {p.properties.phone && (
                  <div>
                    {intl.formatMessage(messages.phone)}:{' '}
                    <a href={`tel:${p.properties.phone}`}>
                      {p.properties.phone}
                    </a>
                  </div>
                )}
                {p.properties.mail && (
                  <div>
                    {intl.formatMessage(messages.mail)}:{' '}
                    <a href={`mailto:${p.properties.mail}`}>
                      {p.properties.mail}
                    </a>
                  </div>
                )}
                {(p.properties.scheda || p.properties.website) && (
                  <div className="popup-bottom">
                    {p.properties.website && (
                      <UniversalLink href={p.properties.website}>
                        {intl.formatMessage(messages.website)}
                      </UniversalLink>
                    )}
                    {p.properties.scheda && (
                      <UniversalLink href={getUrlScheda(p)}>
                        {intl.formatMessage(messages.scheda)}
                      </UniversalLink>
                    )}
                  </div>
                )}
              </div>
            ),
            divIcon: LeafIcon(
              {
                className: p.properties.color ?? '',
              },
              p,
            ),
          })) ?? []
      );
    }
  });

  const loading = useSelector(
    (state) =>
      state.YCApi.subrequests?.[subrequestsSuffixID + 'subjects']?.loading ||
      state.YCApi.subrequests?.[subrequestsSuffixID + 'comuni']?.loading ||
      state.YCApi.subrequests?.[subrequestsSuffixID + 'province']?.loading ||
      state.YCApi.subrequests?.[subrequestsSuffixID + 'tipiSpazi']?.loading,
  );

  const store = useStore();

  useEffect(() => {
    if (comuniYouz.length === 0 && markers?.length > 0) {
      const comuni_youz = store.getState().querystringsearch?.subrequests
        ?.tappe_mappa_youz?.items;
      let cities = [];
      if (comuni_youz?.length > 0) {
        comuni_youz.forEach((t) => {
          const filteredCities = cities.filter((c) => c.value === t.city);
          if (filteredCities.length === 0) {
            cities.push({
              value: t.city,
              label: t.city,
            });
          }
        });
      }
      setComuniYouz(cities);
    }
  }, [markers]);

  const [center, setCenter] = useState(null);

  const getDefaultMapParams = (maptype) => {
    if (maptype === MAP_TYPE_SPAZI_E_SERVIZI) {
      return { spazi: 'on' };
    }
    if (maptype === MAP_TYPE_CREATIVITA) {
      return {
        spazi: 'on',
        catSpazio: [CAT_SPAZIO.SALA_PROVE, CAT_SPAZIO.AGGREGAZIONE_POLIVALENTE],
      };
    }
    if (maptype === MAP_TYPE_WEB_RADIO) {
      return { spazi: 'on', catSpazio: CAT_SPAZIO.WEBRADIO };
    }
    if (maptype === MAP_TYPE_STREETART_SPAZI_CREATIVI) {
      return {
        spazi: 'on',
        catSpazio: [CAT_SPAZIO.STREETART, CAT_SPAZIO.SPAZICREATIVI],
      };
    }
    return null;
  };

  const getFiltersTappe = () => {
    let queryFilters = [];
    const date_fmt = 'YYYY-MM-DDTHH:mm';
    queryFilters.push({
      i: 'portal_type',
      o: 'plone.app.querystring.operation.selection.any',
      v: ['Stage'],
    });

    if (filters.comune) {
      queryFilters.push({
        i: 'city',
        o: 'plone.app.querystring.operation.string.contains',
        v: filters.comune.value,
      });
    }

    if (filters.status_tappe_youz?.value === 'prev') {
      queryFilters.push({
        i: 'closing_date',
        o: 'plone.app.querystring.operation.date.lessThan',
        v: moment().startOf('day')?.format(date_fmt),
      });
    }

    if (filters.status_tappe_youz?.value === 'next') {
      queryFilters.push({
        i: 'closing_date',
        o: 'plone.app.querystring.operation.date.largerThan',
        v: moment().startOf('day')?.format(date_fmt),
      });
    }

    return queryFilters;
  };

  const doSearchTappe = () => {
    dispatch(
      getQueryStringResults(
        '/',
        {
          fullobjects: 1,
          query: getFiltersTappe(),
          b_size: 500,
        },
        'tappe_mappa_youz',
        1,
      ),
    );
  };

  useEffect(() => {
    if (map_type === MAP_TYPE_TAPPE_YOUZ) {
      doSearchTappe();
    } else {
      dispatch(getYCApi(subrequestsSuffixID, 'province'));
      dispatch(getYCApi(subrequestsSuffixID, 'comuni'));
      if (map_type === MAP_TYPE_SPAZI_E_SERVIZI) {
        dispatch(getYCApi(subrequestsSuffixID, 'tipiSpazi'));
      }
      if (map_type === MAP_TYPE_YC) {
        dispatch(getYCApi(subrequestsSuffixID, 'convenzione_categorie'));
      }

      let params = getDefaultMapParams(map_type);

      dispatch(getYCApi(subrequestsSuffixID, 'subjects', params)); //elementi della mappa
    }
  }, [map_type]);

  useEffect(() => {
    doSearchTappe();
  }, [filters.status_tappe_youz, filters.comune]);

  useEffect(() => {
    dispatch(
      getYCApi(
        subrequestsSuffixID,
        'comuni',
        filters.provincia ? { provincia: filters.provincia.value } : null,
      ),
    );
  }, [filters.provincia]);

  useEffect(() => {
    let params = {};

    if (filters.provincia) {
      params.provincia = filters.provincia.value;
    }
    if (filters.comune) {
      params.comune = filters.comune.value;
    }

    if (map_type === MAP_TYPE_YC) {
      if (filters.progetti) {
        params.progetti = 'on';
      }
      if (filters.convenzioni) {
        params.convenzioni = 'on';
      }
      if (filters.punti_distribuzione) {
        params.punti = 'on';
      }
    }

    const defaultParams = getDefaultMapParams(map_type);
    if (defaultParams) {
      params = { ...params, ...defaultParams };
    }

    if (map_type === MAP_TYPE_SPAZI_E_SERVIZI) {
      if (filters.tipologia_spazio_servizio) {
        params.catSpazio = filters.tipologia_spazio_servizio.value;
      }
    }

    if (map_type === MAP_TYPE_YC) {
      if (filters.convenzioni) {
        if (filters.convenzione_categoria) {
          params.catConvezione = filters.convenzione_categoria.value;
        }
      }
    }

    if (map_type === MAP_TYPE_YC) {
      if (filters.convenzioni) {
        if (filters.convenzione_categoria) {
          params.catConvezione = filters.convenzione_categoria.value;
        }
      }
    }

    if (map_type === MAP_TYPE_STREETART_SPAZI_CREATIVI) {
      if (filters.street_art || filters.spazi_creativi) {
        params.catSpazio = [];
        if (filters.street_art) {
          params.catSpazio.push(CAT_SPAZIO.STREETART);
        }
        if (filters.spazi_creativi) {
          params.catSpazio.push(CAT_SPAZIO.SPAZICREATIVI);
        }
      }
    }

    if (map_type !== MAP_TYPE_TAPPE_YOUZ) {
      dispatch(getYCApi(subrequestsSuffixID, 'subjects', params));
    }
  }, [filters]);

  const handlePin = (r) => {
    setCenter(r);
    setZoom(18);
  };
  const handleFocus = (event, item, index, arrMarkers) => {
    switch (event.keyCode) {
      case 13: // Enter
        handlePin(item);
        break;
      case 40: //arrow down
        event.preventDefault();
        if (index < markers.length - 1) {
          document.getElementById('pin' + arrMarkers[index + 1].id).focus();
        }
        break;
      case 38: //arrow up
        event.preventDefault();
        if (index <= markers.length - 1 && index !== 0) {
          document.getElementById('pin' + arrMarkers[index - 1].id).focus();
        }
        break;
      case 32: // Spacebar
        event.preventDefault();
        window.location.href = item.href;
        handlePin(item);
        break;
      default:
        break;
    }
  };

  return (
    <div className="bg-wrapper full-width">
      <Container>
        {data.title && <h2 className="text-center">{data.title}</h2>}

        {map_type === MAP_TYPE_YC && (
          <div className="top-filters">
            <Grid stackable>
              <Grid.Row columns={3}>
                <Grid.Column>
                  <Checkbox
                    label={intl.formatMessage(messages.convenzioni)}
                    checked={filters.convenzioni}
                    onChange={() =>
                      setFilters({
                        ...filters,
                        convenzioni: !filters.convenzioni,
                      })
                    }
                    id="convenzioni_check"
                    name="convenzioni_check"
                    aria-controls="youngercardmap-results"
                  />
                </Grid.Column>
                <Grid.Column>
                  <Checkbox
                    label={intl.formatMessage(messages.progetti)}
                    checked={filters.progetti}
                    onChange={() =>
                      setFilters({ ...filters, progetti: !filters.progetti })
                    }
                    id="progetti_check"
                    name="progetti_check"
                    aria-controls="youngercardmap-results"
                  />
                </Grid.Column>
                <Grid.Column>
                  <Checkbox
                    label={intl.formatMessage(messages.punti_distribuzione)}
                    checked={filters.punti_distribuzione}
                    onChange={() =>
                      setFilters({
                        ...filters,
                        punti_distribuzione: !filters.punti_distribuzione,
                      })
                    }
                    id="puntidistribuzione_check"
                    name="puntidistribuzione_check"
                    aria-controls="youngercardmap-results"
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
        )}

        {map_type === MAP_TYPE_STREETART_SPAZI_CREATIVI && (
          <div className="top-filters">
            <Grid stackable>
              <Grid.Row columns={3}>
                <Grid.Column>
                  <Checkbox
                    label={intl.formatMessage(messages.street_art)}
                    checked={filters.street_art}
                    onChange={() =>
                      setFilters({
                        ...filters,
                        street_art: !filters.street_art,
                      })
                    }
                    id="streetart_check"
                    aria-controls="youngercardmap-results"
                  />
                </Grid.Column>
                <Grid.Column>
                  <Checkbox
                    label={intl.formatMessage(messages.spazi_creativi)}
                    checked={filters.spazi_creativi}
                    onChange={() =>
                      setFilters({
                        ...filters,
                        spazi_creativi: !filters.spazi_creativi,
                      })
                    }
                    id="spazicreativi_check"
                    aria-controls="youngercardmap-results"
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
        )}

        <div className="map-wrapper">
          {/* Results bar*/}
          <div
            className={`map-results ${
              collapsedFilters ? 'collapsed' : 'expanded'
            }`}
          >
            {/* Collapse button */}
            <div className="collapse-button">
              <button
                onClick={() => {
                  setCollapsedFilters(!collapsedFilters);
                }}
                title={
                  collapsedFilters
                    ? intl.formatMessage(messages.arrow_open)
                    : intl.formatMessage(messages.arrow_close)
                }
              >
                <FontAwesomeIcon
                  icon={[
                    'fas',
                    collapsedFilters ? 'arrow-right' : 'arrow-left',
                  ]}
                />
              </button>
            </div>

            {/* More filters */}
            <div className="more-filters">
              {/* COMUNE e PROVINCIA */}
              {map_type !== MAP_TYPE_TAPPE_YOUZ && (
                <div className="react-select-wrap" id="selectProvincia">
                  <Select
                    value={filters?.provincia ?? null}
                    aria-label={intl.formatMessage(messages.provincia)}
                    placeholder={intl.formatMessage(messages.provincia)}
                    aria-controls="youngercardmap-results"
                    options={province}
                    classNamePrefix="react-select"
                    onChange={(v) => {
                      setFilters({
                        ...filters,
                        provincia: v,
                      });
                    }}
                    isClearable={true}
                    ariaLiveMessages={getReactSelectAriaLiveMessages(intl)}
                    screenReaderStatus={getReactSelectScreenReaderStatus(intl)}
                    escapeClearsValue={true}
                  />
                </div>
              )}

              <div className="react-select-wrap" id="selectComune">
                <Select
                  value={filters?.comune ?? null}
                  aria-label={intl.formatMessage(messages.comune)}
                  placeholder={intl.formatMessage(messages.comune)}
                  aria-controls="youngercardmap-results"
                  options={
                    map_type === MAP_TYPE_TAPPE_YOUZ ? comuniYouz : comuni
                  }
                  classNamePrefix="react-select"
                  onChange={(v) => {
                    setFilters({
                      ...filters,
                      comune: v,
                    });
                  }}
                  isClearable
                  ariaLiveMessages={getReactSelectAriaLiveMessages(intl)}
                  screenReaderStatus={getReactSelectScreenReaderStatus(intl)}
                  escapeClearsValue
                />
              </div>

              {/* TIPO SPAZIO/SERVIZIO */}
              {map_type === MAP_TYPE_SPAZI_E_SERVIZI && (
                <div className="react-select-wrap" id="selectServizio">
                  <Select
                    value={filters?.tipologia_spazio_servizio ?? null}
                    aria-label={intl.formatMessage(
                      messages.tipologia_spazio_servizio,
                    )}
                    placeholder={intl.formatMessage(
                      messages.tipologia_spazio_servizio,
                    )}
                    aria-controls="youngercardmap-results"
                    options={tipologie_spazi_e_servizi || []}
                    classNamePrefix="react-select"
                    onChange={(v) => {
                      setFilters({
                        ...filters,
                        tipologia_spazio_servizio: v,
                      });
                    }}
                    ariaLiveMessages={getReactSelectAriaLiveMessages(intl)}
                    screenReaderStatus={getReactSelectScreenReaderStatus(intl)}
                    isClearable
                    escapeClearsValue
                  />
                </div>
              )}

              {/* TIPO TAPPE YOUZ */}
              {map_type === MAP_TYPE_TAPPE_YOUZ && (
                <div className="react-select-wrap" id="selectTappeYouz">
                  <Select
                    value={filters?.status_tappe_youz ?? null}
                    aria-label={intl.formatMessage(messages.status_tappe_youz)}
                    placeholder={intl.formatMessage(messages.status_tappe_youz)}
                    aria-controls="youngercardmap-results"
                    options={status_tappe_youz}
                    classNamePrefix="react-select"
                    onChange={(v) => {
                      setFilters({
                        ...filters,
                        status_tappe_youz: v,
                      });
                    }}
                    ariaLiveMessages={getReactSelectAriaLiveMessages(intl)}
                    screenReaderStatus={getReactSelectScreenReaderStatus(intl)}
                    isClearable
                    escapeClearsValue
                  />
                </div>
              )}

              {/* CONVENZIONE CATEGORIE */}
              {map_type === MAP_TYPE_YC && filters.convenzioni && (
                <div className="react-select-wrap" id="selectCategorie">
                  <Select
                    value={filters?.convenzione_categoria}
                    aria-label={intl.formatMessage(
                      messages.convenzione_categorie,
                    )}
                    placeholder={intl.formatMessage(
                      messages.convenzione_categorie,
                    )}
                    aria-controls="youngercardmap-results"
                    options={convenzione_categorie}
                    classNamePrefix="react-select"
                    onChange={(v) => {
                      setFilters({
                        ...filters,
                        convenzione_categoria: v,
                      });
                    }}
                    ariaLiveMessages={getReactSelectAriaLiveMessages(intl)}
                    screenReaderStatus={getReactSelectScreenReaderStatus(intl)}
                    isClearable
                    escapeClearsValue
                  />
                </div>
              )}
            </div>

            <div
              className="results-list"
              id="youngercardmap-results"
              aria-live="polite"
              aria-atomic={true}
              role="region"
            >
              {markers?.length === 0 ? (
                <div className="no-results">
                  {intl.formatMessage(messages.no_results)}
                </div>
              ) : (
                <div className="results-found">
                  {intl.formatMessage(messages.results_found, {
                    number: markers?.length,
                  })}
                </div>
              )}
              <div id="keyboardInstructions" className="sr-only" role="note">
                {intl.formatMessage(messages.keyboardInstructions)}
              </div>
              {markers?.map((item, index, arrMarkers) => (
                <div
                  role="button"
                  tabIndex={index === 0 ? 0 : -1}
                  id={`pin${item.id}`}
                  className={`item-wrapper ${
                    item.latitude === center?.latitude &&
                    item.longitude === center?.longitude
                      ? 'selected'
                      : ''
                  }`}
                  onClick={() => {
                    // eslint-disable-next-line no-unused-expressions
                    item.latitude !== center?.latitude &&
                    item.longitude !== center?.longitude
                      ? handlePin(item)
                      : null;
                  }}
                  onKeyDown={(event) => {
                    handleFocus(event, item, index, arrMarkers);
                  }}
                  key={item.id}
                  aria-describedby="keyboardInstructions"
                >
                  <div className="item">
                    <div className="title">{item.title}</div>
                    <div className="address">{item.address}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Mappa */}
          {!loading && (
            <div aria-hidden="true">
              <YCMap
                markers={markers}
                center={center}
                zoom={zoom}
                key={props.id}
              />
            </div>
          )}
          <Dimmer active={loading}>
            <Loader indeterminate></Loader>
          </Dimmer>
        </div>
        <ReadMore
          linkMore={data.linkMore}
          isEditMode={isEditMode}
          color="white"
        />
      </Container>
    </div>
  );
};

export default React.memo(Body);
